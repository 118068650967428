import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import { auth } from './auth.module';
import { user } from './user.module';
import { upload } from './upload.module';

Vue.use(Vuex);

// js-cookie to use browser Cookies
// window.localStorage (remains, across PC reboots, untill you clear browser data)
// window.sessionStorage (vanishes when you close browser tab)
// localForage Uses IndexedDB from the browser

const vuexLocal = new VuexPersist({
  storage: window.localStorage,
  modules: ['auth', 'user','upload'],
});

const vuexSession = new VuexPersist({
  storage: window.sessionStorage,
  modules: [''],
});

export default new Vuex.Store({
  modules: {
    auth,
    user,
    upload
  },
  plugins: [vuexSession.plugin, vuexLocal.plugin],
});
