<template>
  <div id="icon-menu-check">
    <b-navbar-toggle v-b-toggle.sidebar-backdrop>
      <img
        src="/img/icons/svg/line_horizontal_filled_icon_200524_fff.svg"
        height="30"
        alt=""
      />
    </b-navbar-toggle>
    <div>
      <!-- title="Sidebar with backdrop" -->
      <b-sidebar id="sidebar-backdrop" backdrop-variant="light" backdrop shadow>
        <sidebarHeader></sidebarHeader>
        <div class="py-2">
          <b-navbar-nav vertical>
            <b-nav-item-dropdown class="font-left" left>
              <!-- Using 'button-content' slot -->
              <template #button-content> &nbsp;&nbsp;&nbsp; Shop </template>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'shop-add' }"
              >
                Add New
              </b-dropdown-item>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'shop-list' }"
              >
                Shop List
              </b-dropdown-item>
              <!-- <b-dropdown-item class="dropdown-link-hover" href="#">
                Add Status
              </b-dropdown-item> -->
            </b-nav-item-dropdown>

            <b-nav-item-dropdown class="font-left" left>
              <template #button-content> &nbsp;&nbsp;&nbsp; Order </template>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'order-add' }"
              >
                Add New
              </b-dropdown-item>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'order-list' }"
              >
                Order List
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown class="font-left" left>
              <template #button-content>
                &nbsp;&nbsp;&nbsp; Order Item
              </template>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'order-item-list' }"
              >
                Add New
              </b-dropdown-item>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'item-list' }"
              >
                Item List
              </b-dropdown-item>
              <!-- <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'invoice-issue' }"
              >
                Export as Excel
              </b-dropdown-item> -->
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'upload-file' }"
              >
                Upload File
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- <b-nav-item-dropdown class="font-left" left>
              <template #button-content>
                &nbsp;&nbsp;&nbsp; Upload File
              </template>
            </b-nav-item-dropdown> -->
            <b-nav-item-dropdown class="font-left" left>
              <template #button-content> &nbsp;&nbsp;&nbsp; User </template>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'user-add' }"
              >
                Add New
              </b-dropdown-item>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'user-list' }"
              >
                User List
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <!-- Using 'button-content' slot -->
            <b-dropdown-item
              class="dropdown-link-hover mt-3"
              style="text-align: left"
              :to="{ name: 'my-activity' }"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;My Activity
            </b-dropdown-item>

            <b-dropdown-item
              class="dropdown-link-hover my-4"
              style="text-align: left"
              :to="{ name: 'myinfo' }"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;My Profile
            </b-dropdown-item>
            <!-- <b-nav-item-dropdown class="font-left" left> -->
            <!-- Using 'button-content' slot -->
            <b-dropdown-item
              class="dropdown-link-hover"
              style="text-align: left"
              href="#"
              v-on:click.prevent="logOut()"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Log Out
            </b-dropdown-item>
            <!-- </b-nav-item-dropdown> -->
            <!-- <b-nav-item class="font-left" href="#link-1" @click="hide">
              &nbsp;&nbsp;&nbsp; Link
            </b-nav-item> -->
          </b-navbar-nav>
        </div>
        <div class="footer">
          <hr class="footer-line" />
          Version : {{ version }}
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import SidebarHeader from './dropdownMenu/SidebarHeader.vue';

export default {
  components: {
    SidebarHeader,
  },
  data() {
    return {
      version: null,
    };
  },
  beforeMount() {
    this.version = process.env.VUE_APP_VERSION;
  },
  methods: {
    logOut: function () {
      this.$emit('send-logOut');
    },
  },
};
</script>

<style></style>
