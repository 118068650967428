import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import VueSession from 'vue-session';
// Vue.use(VueSession);

import setupInterceptors from './services/setupInterceptors';
setupInterceptors();

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../src/assets/css/global-style.css';
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

require('./plugin/vee-validate');

Vue.config.productionTip = false;

// show image full-size
import VueImg from 'v-img';
Vue.component("v-select", vSelect);
Vue.use(VueImg);

// Load Vuex
Vue.use(Vuex);
Vue.use(store);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
