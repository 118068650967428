import AuthService from '../services/auth.service';
// console.log("hi");
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    isAuthenticated(state) {
      // console.log("is authenticated");
      return state.status.loggedIn;
    },
    currentUser(state) {
      //   return "ba ma bash";
      return state.status.user;
    },
    getRolesLabel(state) {
      return state.status.rolesLabel;
    },
    getUserId(state) {
      return state.status.userId;
    },
  },

  actions: {
    login({ commit }, formData) {
      return AuthService.login(formData)
        .then(
          (response) => {
            commit('loginSuccess', response);
            return Promise.resolve(response.data);
          },
          (error) => {
            commit('loginFailure');
            return Promise.reject(error.response.data);
          }
        )
        .catch((error) => {
          this.error = error;
          commit('loginFailure');
          return Promise.reject(error);
        });
    },

    async resetPassword(formData) {
      return AuthService.resetPassword(formData).then(
        (response) => {
          console.log(response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    accessToken({ commit }, accessToken) {
      commit('accessToken', accessToken);
    },

    async logout({ commit }, state) {
      await AuthService.logout()
        .then((response) => {
          commit('loginFailure');
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('vuex');
          const s = initialState();
          console.log(s);
          Object.keys(s).forEach((key) => {
            state[key] = s[key];
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          this.error = error;
          return Promise.reject(error);
        });
    },
  },

  mutations: {
    loginSuccess(state) {
      state.status.loggedIn = true;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    rolesLabel(state, rolesLabel) {
      state.status.rolesLabel = rolesLabel;
    },
    userId(state, userId) {
      state.status.userId = userId;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.auth = null;
      state.user = null;
      state.root = null;
      state.status.rolesLabel = null;
    },
    verifiedFailure(state) {
      state.status.loggedIn = false;
    },
    accessToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
  },
};
