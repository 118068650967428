<template>
  <div id="app">
    <div id="main">
      <div id="main-container">
        <div v-if="isLoggedIn">
          <div v-if="show">
            <HeaderBar></HeaderBar>
          </div>
        </div>

        <div id="main-router-view">
          <router-view />
        </div>

        <div v-if="isLoggedIn">
          <FooterBar></FooterBar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from './components/layouts/HeaderBar.vue';
import FooterBar from './components/layouts/FooterBar.vue';
import { App } from '@capacitor/app';

export default {
  name: 'App',
  components: {
    HeaderBar,
    FooterBar,
  },
  data() {
    return {
      // isLoggedIn: true,
      getNowTime: 0,
      opportunityTime: 0,
      timeLeft: 0,
      show: true,
    };
  },
  mounted() {
    App.addListener('backButton', ({ canGoBack }) => {
      if (this.routerName !== 'home' && canGoBack) {
        window.history.back();
      } else if (this.routerName == 'home') {
        App.exitApp();
      }
    });
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    routerName() {
      return this.$route.name;
    },
  },
  // methods: {
  // },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#main-router-view {
  /* background-color: #42b983; */
  margin-bottom: 150px;
  display: inline-block !important;
  min-height: 550px;
  width: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
