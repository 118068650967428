import Vue from 'vue';
import VueRouter from 'vue-router';
// import HomeView from '../views/HomeView.vue';

// import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView,
    component: () => import('../views/HomePage.vue'),
  },
  {
    path: '/myinfo',
    name: 'myinfo',
    component: () => import('../components/user/MyInformation.vue'),
  },
  {
    path: '/shop/info',
    name: 'shop-info',
    component: () => import('../components/business/ShopInfo.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login/LogPage.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/forgetpassword',
    name: 'forgetpassword',
    component: () => import('../components/login/LogPage.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/ResetPassword/:token',
    name: 'ResetPassword',
    component: () => import('../components/login/LogPage.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('../components/404/404Page.vue'),
  },
  {
    path: '/uploadfile',
    name: 'upload-file',
    component: () => import('../components/UploadFile.vue'),
  },
  {
    path: '/user/add',
    name: 'user-add',
    component: () => import('../components/user/CreateUser.vue'),
  },
  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('../components/user/UserList.vue'),
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: () => import('../components/user/EditUser.vue'),
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../components/user/UserDetails.vue'),
  },
  {
    path: '/user/update/:id',
    name: 'user-update',
    component: () => import('../components/user/EditUser.vue'),
  },
  {
    path: '/shop/add',
    name: 'shop-add',
    component: () => import('../components/business/CreateBusiness.vue'),
  },
  {
    path: '/shop/add/labor',
    name: 'shop-add-labor',
    component: () => import('../components/user/CreateLabor.vue'),
  },
  {
    path: '/shop/add/qualitycontroller',
    name: 'add-quality-controller',
    component: () => import('../components/user/CreateQualityController.vue'),
  },
  {
    path: '/shop/list/qualitycontroller',
    name: 'list-quality-controller',
    component: () => import('../components/business/QualityControllerList'),
  },
  {
    path: '/shop/list/labor',
    name: 'shop-list-labor',
    component: () => import('../components/business/ListLabor.vue'),
  },
  {
    path: '/shop/list/customer',
    name: 'shop-list-customer',
    component: () => import('../components/business/ListCustomer.vue'),
  },
  {
    path: '/shop/list/order',
    name: 'shop-list-order',
    component: () => import('../components/business/ListOrder.vue'),
  },
  {
    path: '/shop/add/customer',
    name: 'shop-add-customer',
    component: () => import('../components/user/CreateCustomer.vue'),
  },
  {
    path: '/shop/list',
    name: 'shop-list',
    component: () => import('../components/business/BusinessList.vue'),
  },
  {
    path: '/shop/:id',
    name: 'shop',
    component: () => import('../components/business/BusinessDetails.vue'),
  },
  {
    path: '/shop/edit/:id',
    name: 'shop-edit',
    component: () => import('../components/business/UpdateBusiness.vue'),
  },
  {
    path: '/shop/status/:id',
    name: 'status-add',
    component: () => import('../components/business/CreateBusinessStatus.vue'),
  },
  {
    path: '/shop/status/update/:id',
    name: 'update-status',
    component: () => import('../components/business/EditBusinessStatus.vue'),
  },
  {
    path: '/order/add',
    name: 'order-add',
    component: () => import('../components/order/CreateOrder.vue'),
  },
  {
    path: '/order/list',
    name: 'order-list',
    component: () => import('../components/order/OrderList.vue'),
  },
  {
    path: '/order/update/:id',
    name: 'order-update',
    component: () => import('../components/order/EditOrder.vue'),
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('../components/order/ShowOrder.vue'),
  },
  {
    path: '/order/item/:id',
    name: 'order-item',
    component: () => import('../components/item/OrderItem.vue'),
  },
  {
    path: '/list/order/item/:id',
    name: 'list-order-item',
    component: () => import('../components/item/ListOrderItem.vue'),
  },
  {
    path: '/item/qr/:id',
    name: 'item-qr',
    component: () => import('../components/item/QrCodeItem.vue'),
  },
  {
    path: '/item/list',
    name: 'item-list',
    component: () => import('../components/item/ItemList.vue'),
  },
  {
    path: '/item/print/:id',
    name: 'item-print',
    component: () => import('../components/item/ItemListPrint.vue'),
  },
  {
    path: '/item/list/invoice',
    name: 'invoice-issue',
    component: () => import('../components/item/InvoiceIssue.vue'),
  },
  {
    path: '/item/selected',
    name: 'item-selected',
    component: () => import('../components/item/InvoiceSelected.vue'),
  },
  {
    path: '/item/edit/:id',
    name: 'item-edit',
    component: () => import('../components/item/EditItem.vue'),
  },
  {
    path: '/order/list/items',
    name: 'order-item-list',
    component: () => import('../components/item/OrderItemList.vue'),
  },
  {
    path: '/item/:id',
    name: 'item',
    component: () => import('../components/item/ItemDetail.vue'),
  },
  {
    path: '/item/log/:id',
    name: 'item-log',
    component: () => import('../components/item/ItemStatusLog.vue'),
  },
  {
    path: '/item/todo/:id',
    name: 'item-todo',
    component: () => import('../components/labor/ChangeStatus.vue'),
  },
  {
    path: '/show/scanform/:id',
    name: 'show-scan-form',
    component: () => import('../components/labor/ShowForm.vue'),
  },
  {
    path: '/scan/qr',
    name: 'scan-qr',
    component: () => import('../components/item/scanQr.vue'),
  },
  {
    path: '/my-activity',
    name: 'my-activity',
    component: () => import('../components/user/MyActivity.vue'),
  },
  {
    path: '/my-order',
    name: 'my-order',
    component: () => import('../components/user/MyOrder.vue'),
  },
  {
    path: '/qr/:id',
    name: 'qr-code',
    component: () => import('../components/item/QrDetails.vue'),
  },

  {
    path: '/ImageEditing',
    name: 'ImageEditing',
    component: () => import('../components/layouts/ImageEditing.vue'),
  },
  {
    path: '/todo/list',
    name: 'todo',
    component: () => import('../components/user/MyTodo.vue'),
  },
  {
    path: '/template',
    name: 'template',
    component: () => import('../components/form/listTemplate.vue'),
  },
  {
    path: '/TwoPartTemplate',
    name: 'TwoPartTemplate',
    component: () => import('../components/form/TwoPartTemplate.vue'),
  },
  {
    path: '/TextTemplate',
    name: 'TextTemplate',
    component: () => import('../components/form/TextTemplate.vue'),
  },
  {
    path: '/FormTemplate',
    name: 'FormTemplate',
    component: () => import('../components/form/FormTemplate.vue'),
  },
  {
    path: '/EmptyFormTemplate',
    name: 'EmptyFormTemplate',
    component: () => import('../components/form/EmptyFormTemplate.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // to.matched[0].meta.requiresAuth
  // console.log(to.matched[0].meta);
  let access_token = localStorage.getItem('access_token');
  if (access_token) {
    next();
  } else {
    if (
      to.name == 'login' ||
      to.name == 'forgetpassword' ||
      to.name == 'ResetPassword'
    ) {
      next();
    } else {
      // console.log('signin False');
      next({ name: 'login' });
    }
  }
});

export default router;
