import UserService from '../services/user.service';
// import router from '@/router';

export const user = {
  namespaced: true,
  state: {
    verifiedPassed: '',
    MyProfile: '',
    myId: '',
    myFirstName:'',
    myLastName:'',
    Role:'',
    businessId:''
  },
  getters: {
    getVerifiedPassed(state) {
      return state.verifiedPassed;
    },
    getMyProfileSuccess(state) {
      return state.MyProfile;
    },
    myFirstName(state) {
      return state.myFirstName;
    },
    myLastName(state) {
      return state.myLastName;
    },
    myBusinessId(state) {
      return state.businessId;
    },
    getUserAvatarById(state) {
      return state.userByIdAvatar;
    },
    getRole(state) {
      return state.Role;
    },
    getMyId(state) {
      return state.myId;
    },
    getPublicMeetingRequest(state) {
      return state.publicMeetingRequest;
    },
  },
  actions: {
    // updateMyProfile({ commit }, formData) {
    //   return UserService.updateMyProfile(formData).then(
    //     (response) => {
    //       localStorage.setItem('firstLogin', false);
    //       commit('updateMyProfileSuccess');
    //       commit('verifiedPassed');
    //       return Promise.resolve(response);
    //     },
    //     (error) => {
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    async getUserInfo({ commit }) {
      return UserService.getUserInfo().then(
        (response) => {
          let  BusinessId;
          if(response.data.business !=null){
            BusinessId=response.data.business.Id;
          }
          else BusinessId =null
          const myFirstName = response.data.firstName;
          const myLastName = response.data.lastName;
          const Role = response.data.role.label;
          const myId=response.data.userId;
          commit('myFirstName', myFirstName);
          commit('myLastName', myLastName);
          commit('myId', myId);
          commit('myBusinessId', BusinessId);
          commit('Role', Role);
          commit('myProfileSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    // createUser({ commit }, userData) {
    //   return UserService.store(userData)
    //     .then((res) => {
    //       commit('createUserSuccess');
    //       return Promise.resolve(res);
    //     })
    //     .catch((err) => {
    //       return Promise.reject(err);
    //     });
    // },
    // getUserInfoById({ commit }, id) {
    //   return UserService.getUserById(id)
    //     .then((response) => {
    //       // console.log(response);
    //       // let Role = response.data.data.roles[0].label;
    //       let avatarId = response.data.data.avatar;

    //       commit('userAvatarById', avatarId);
    //       return response;
    //     })
    //     .catch((error) => {
    //       return Promise.reject(error);
    //     });
    // },
  },
  mutations: {
    updateMyProfileSuccess() {
      // router.push('/');
    },
    verifiedPassed(state) {
      state.verifiedPassed = true;
    },
    myProfileSuccess(state, myProfile) {
      state.MyProfile = myProfile;
    },
    createUserSuccess() {
      // console.log('create cimmit success');
      // state.UserService = userData;
    },
    myFirstName(state, myFirstName) {
      state.myFirstName = myFirstName;
    },
    myId(state, myId) {
      state.myId = myId;
    },
    myLastName(state, myLastName) {
      state.myLastName = myLastName;
    },
    myBusinessId(state, businessId) {
      state.businessId = businessId;
    },
    Role(state, Role) {
      state.Role = Role;
    },
    publicMeetingRequest(state, publicMeetingRequest) {
      //   console.log('commit publicMeetingRequest ' + publicMeetingRequest);
      state.publicMeetingRequest = publicMeetingRequest;
    },
  },
};
