import api from './api';

class UserService {
  async store(formData) {
    return api
      .post('/user/store', formData)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err.response);
        return Promise.reject(err);
      });
  }

  async storeLabor(formData) {
    return api
      .post('user/storeBusinessLabor', formData)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err.response);
        return Promise.reject(err);
      });
  }
  async storeQualityControl(formData) {
    return api
      .post('user/storeQualityControl', formData)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err.response);
        return Promise.reject(err);
      });
  }
  async storeCustomer(formData) {
    console.log(formData);
    return api
      .post('user/storeBusinessCustomer', formData)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err.response);
        return Promise.reject(err);
      });
  }
  async UpdateUser(formData, id) {
    return api
      .put('/user/update/' + id, formData)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  async getUserInfo() {
    return api
      .get('/user/myInformation')
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  async userShow(userId) {
    return api
      .get('/user/show/' + userId)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err.response);
        return Promise.reject(err);
      });
  }

  userList(page) {
    return api.get('user/get?page=' + page);
  }

  async destroyUser(id) {
    return api
      .delete('user/destroy/' + id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return Promise.reject(err.response);
      });
  }
}

export default new UserService();
