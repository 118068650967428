<template>
  <div>
    <div class="infobar">
      <b-avatar class="mb-2" size="30%" rounded="sm"></b-avatar>
      <div id="text-prof">
      <div class="role-title">{{ role }}</div>
       <div> {{ firstName }}&nbsp;&nbsp;{{lastName}} </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  data() {
    return {
      roles_label: '',
      imageName: 'avatar.jpeg',
    };
  },
  async beforeMount() {
    this.getUserprofile();
  },
  mounted() {
    this.$store.dispatch('user/getUserInfo');
  },
  computed: {
    ...mapGetters({
      role: ['user/getRole'],
      firstName: ['user/myFirstName'],
      lastName: ['user/myLastName'],
    }),
  },
  methods: {
    async getUserprofile() {

    },
  },
};
</script>

<style>
.infobar {
  margin-bottom: 2.5% !important;
  text-align: center;
  color: #165a31;
}
#icon-close-slider {
  width: 27px;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  padding: 1px;
}
#icon-close-div {
  width: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
}
</style>

<style scoped>
.role-title {
  display: inline-block;
  width: 31vw;
  text-align: center;
  margin: auto 2.5% auto 0.5%;
}
#sidebar-header-avatar {
  background-color: #475065 !important;
  min-height: 150px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.29);
}
#text-prof{
display: grid;
width: 100%;
justify-content: center;
line-height: 31px;
}
</style>
