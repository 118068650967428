import api from './api';
import TokenService from '../services/token.service';
import Swal from 'sweetalert2';

const setup = () => {
  api.interceptors.request.use(
    (config) => {
      let token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (resp) => {
      if (resp.status == 200 && resp.data.success == false) {
        Swal.fire({
          icon: 'warning',
          title: 'somthing was wrong',
          text: 'try again !',
          showConfirmButton: false,
          timer: 1800,
        }).then(() => {
          console.log(resp.data.message);
        });
        return Promise.reject(resp.data.success);
        // alert(resp.data.message)
      } else if (resp.status == 200) {
        const fe_version = resp.headers['fe-version'] || 'default';
        if (
          fe_version !== localStorage.getItem('fe-version') &&
          resp.config.method == 'get'
        ) {
          localStorage.setItem('fe-version', fe_version);
          window.location.reload(); // For new version, simply reload on any get
        }
        return Promise.resolve(resp);
      }
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.message == 'Network Error') {
        Swal.fire({
          icon: 'info',
          title: 'Network',
          text: 'Check your Network connection. ',
          showConfirmButton: false,
          timer: 1800,
        });
      } else if (originalConfig.url !== '/oauth/token' && err.response) {
        // Access Token was expired
        if (err.response.status == 500) {
          Swal.fire({
            icon: 'error',
            title: 'Server Error',
            text: 'Something is Wrong!',
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (err.response.status == 401) {
          if (!originalConfig._retry) {
            try {
              originalConfig._retry = true;
              const rs = await api.post('/oauth/token', {
                refresh_token: TokenService.getLocalRefreshToken(),
                grant_type: process.env.VUE_APP_OTP_TYPE_REFRESH_TOKEN,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
              });
              TokenService.updateLocalRefreshToken(rs.data.refresh_token);
              TokenService.updateLocalAccessToken(rs.data.access_token);
              return api(originalConfig);
            } catch (_error) {
              Swal.fire({
                icon: 'info',
                title: 'Login Again.',
                text: 'Your Session Expired. ',
                showConfirmButton: false,
                timer: 1800,
                customClass: 'swal-skip',
              }).then(() => {
                localStorage.setItem('logOutCheck', 'true');
                TokenService.removeUser();
                window.location.href = '/login';
              });
              // return Promise.reject(_error);
            }
          }
        } else if (err.response.status == 403) {
          Swal.fire({
            icon: 'warning',
            text: "Don't have permition. ",
            showConfirmButton: false,
            timer: 1600,
            customClass: 'swal-skip',
          }).then(() => {
            // window.location.href = '/';
          });
        } else if (err.response.status == 404) {
          if (err.response.data.message) {
            const swalWithBootstrapButtons = Swal.mixin({});
            swalWithBootstrapButtons.fire({
              text: err.response.data.message,
              icon: 'warning',
              showConfirmButton: false,
              confirmButtonColor: '#3085d6',
              customClass: 'swal-warning',
            });
          } else {
            const swalWithBootstrapButtons = Swal.mixin({});
            swalWithBootstrapButtons.fire({
              text: 'Nothing found ',
              icon: 'warning',
              showConfirmButton: false,
              confirmButtonColor: '#3085d6',
              customClass: 'swal-warning',
            });
          }
        } else {
          const swalWithBootstrapButtons = Swal.mixin({
            // buttonsStyling: false,
          });
          let text = "";
          if(err.response.data.data){
            text = err.response.data.data[
              Object.keys(err.response.data.data)[0]
            ][0].message;
          } else {
            text = err.response.data.message;
          }
          swalWithBootstrapButtons.fire({
            text: text,
            icon: 'error',
            confirmButtonColor: '#3085d6',
            showConfirmButton: false,
            confirmButtonText: 'ok',
            customClass: 'swal-warning',
          });
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
