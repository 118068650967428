import UploadFile from '../services/uploadFile.Service';
// import router from '@/router';

export const upload = {
  namespaced: true,
  state: {
    HashPicture:'',
  },
  getters: {
    getHashPicture(state) {
      return state.HashPicture;
    },
  },
  actions: {
    uploadPicture({ commit }, formData) {
      return UploadFile.UploadPicture(formData).then(
        (response) => {
          console.log(response);
          commit('HashPictures',response.data.pictureHash);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

  },
  mutations: {
    HashPictures(state, HashPicture) {
      state.HashPicture = HashPicture;
    },
  },
};
