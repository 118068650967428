import api from './api';
import TokenService from './token.service';

class AuthService {
  login(formData) {
    // console.log(formData);
    formData.client_id = process.env.VUE_APP_CLIENT_ID;
    formData.client_secret = process.env.VUE_APP_CLIENT_SECRET;
    formData.grant_type = process.env.VUE_APP_OTP_TYPE_ACCESS_TOKEN;
    return api
      .post('/oauth/token', formData)
      .then((response) => {
        // console.log('response: ' + response);
        // console.log('access_token: ' + response.data.access_token);
        TokenService.updateLocalAccessToken(response.data.access_token);
        TokenService.updateLocalRefreshToken(response.data.refresh_token);
        return Promise.resolve(response);
      })
      .catch((err) => {
        // console.log(err.response.data);
        return Promise.reject(err);
      });
  }

  createPassword(formData) {
    // console.log(formData);
    return api
      .post('/password/create?email=' + formData)
      .then((response) => {
        // console.log(response);
        return Promise.resolve(response);
      })
      .catch((error) => {
        // console.log('daliiiii')
        return Promise.reject(error);
      });
  }

  findToken(formData) {
    // console.log(formData);
    return api
      .get('/password/find/' + formData)
      .then((response) => {
        // console.log(response);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  resetPassword(formData) {
    // console.log(formData);
    return api
      .post('/password/reset', formData)
      .then((response) => {
        // console.log(response);
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        return Promise.reject(error);
      });
  }

  logout() {
    return api
      .post('/logout')
      .then((response) => {
        // console.log('hopo');
        TokenService.removeUser();
        return response;
      })
      .catch((error) => console.log(error));
  }
}

export default new AuthService();
