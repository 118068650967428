import api from './api';

class UploadFile {
 async uploadExcel(form){
   const headers = { 'Content-Type': 'multipart/form-data' };
     return api.post('orderItem/importExcel',form,{ headers }).then((res)=>{
        console.log(res);
        return res.data;
     }).catch((error)=>{
        console.log(error);
        return Promise.reject(error);
     })
 }
 async UploadPicture(file){
   const headers = { 'Content-Type': 'multipart/form-data' };
    return api.post('order/uploadFormScan',file,{ headers }).then((res)=>{
       return res.data;
    }).catch((error)=>{
       console.log(error.response);
       return Promise.reject(error);
    })
}

async UploadOrderItemPicture(file){
   const headers = { 'Content-Type': 'multipart/form-data' };
    return api.post('orderItem/uploadFormScan',file,{ headers }).then((res)=>{
       return res.data;
    }).catch((error)=>{
       console.log(error.response);
       return Promise.reject(error);
    })
}
}
export default new UploadFile();
