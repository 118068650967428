<template>
  <div class="print-display-none">
    <!-- Footer -->
    <!-- Copyright -->

    <div
      v-if="
        userRoll == 'labor' ||
        userRoll == 'legal customer' ||
        userRoll == 'quality control'
      "
      id="footer-mobile"
    >
      <!-- labor -->
      <div id="labor-menu">
        <div class="labor-menu-icon" id="menu-left">
          <router-link :to="{ name: 'myinfo' }">
            <img
              v-if="gteRouteName == 'myinfo'"
              src="/img/icons/svg/person_info_filled_icon_200408-8e8e8e.svg"
              height="35"
              alt=""
            />
            <img
              v-else
              src="/img/icons/svg/person_info_filled_icon_200408.svg"
              height="35"
              alt=""
            />
          </router-link>
        </div>
        <div class="labor-menu-home" id="menu-center">
          <router-link :to="{ name: 'home' }">
            <img
              v-if="gteRouteName == 'home'"
              src="/img/icons/svg/homepage_80953-8e8e8e.svg"
              height="35"
              alt=""
            />
            <img
              v-else
              src="/img/icons/svg/homepage_80953.svg"
              height="35"
              alt=""
            />
          </router-link>
        </div>
        <div class="labor-menu-icon" id="menu-right">
          <router-link :to="{ name: 'scan-qr' }">
            <img
              v-if="gteRouteName == 'scan-qr'"
              src="/img/icons/svg/qrcode_scan_icon_136286-8e8e8e.svg"
              height="33"
              alt=""
            />
            <img
              v-else
              src="/img/icons/svg/qrcode_scan_icon_136286.svg"
              height="33"
              alt=""
            />
          </router-link>
        </div>
      </div>
      <p id="footer-version">Version : {{ version }}</p>
    </div>
    <div v-else class="text-center p-4" id="footer-background">
      © 2022 Copyright:
      <a class="text-reset fw-bold" href="#">Dickinson.com</a>
    </div>
    <!-- Copyright -->
    <!-- Footer -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  beforeMount() {
    this.version = process.env.VUE_APP_VERSION;
  },
  mounted() {},
  computed: {
    gteRouteName() {
      return this.$route.name;
    },
    ...mapGetters({
      userRoll: ['user/getRole'],
    }),
  },
};
</script>

<style>
#footer-background {
  background: #ffffff;
  border-width: 2px 1px 0px 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 8px 8px 0px 0px;
  /* position:absolute; */
  bottom: 0;
  width: 100%;
  min-height: 100px;
  max-height: 100px !important;
  margin-top: 50px;
}

#footer-background a {
  text-decoration: none;
}

/* ////////////////////////////////////////////////////mobile */

#footer-mobile {
  background: #ffffff;
  border-width: 2px 1px 0px 1px;
  border-style: solid;
  border-color: #d9d9d9;
  /* border-radius: 20px 20px 0px 0px; */
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px !important;
  margin-top: 100px;
}

#footer-mobile a {
  text-decoration: none;
}

#labor-menu {
  margin-top: 10px;
}

.labor-menu-home {
  /* background-color: red; */
  width: 55%;
  display: inline-block;
}
.labor-menu-icon {
  /* background-color: red; */
  width: 20%;
  display: inline-block;
}
#footer-version {
  width: 100%;
  height: 20px;
  margin-top: 5px;
  color: rgb(144, 145, 145);
}
</style>
