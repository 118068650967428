/* eslint-disable no-console */

// import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('sw.js').then(function () {
    // console.log('Service Worker Registered');
  });
} // },
// registered () {
//   console.log('Service worker has been registered.')
// },
// cached () {
//   console.log('Content has been cached for offline use.')
// },
// updatefound () {
//   console.log('New content is downloading.')
// },
// updated () {
//   console.log('New content is available; please refresh.')
// },
// offline () {
//   console.log('No internet connection found. App is running in offline mode.')
// },
// error (error) {
//   console.error('Error during service worker registration:', error)
// }
// })
// }
// if ('serviceWorker' in navigator){

//   window.addEventListener('load',() => {

//   navigator.serviceWorker.register(`${process.env.BASE_URL}sw.js`)

//   .catch(err => {

//   console.log('Register Failed', err);

//   });

//   });

//   }
