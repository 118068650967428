<template>
  <div
    v-if="
      userRoll == 'labor' ||
      userRoll == 'legal customer' ||
      userRoll == 'quality control'
    "
    class="d-flex"
  >
    <div id="navbar-mobile">
      <div class="display-user-name">
        <div class="logout-labor">
          <router-link to="">
            <img
              src="/img/icons/svg/logoutoutline_110790.svg"
              alt="logOut!"
              title="logOut!"
              @click.prevent="logOut"
              height="35"
            />
          </router-link>
        </div>
        <!-- <b-dropdown variant="primary">
          <template #button-content>
            <router-link to="">
              <img
                src="/img/icons/svg/person_arrow_left_filled_icon_199760.svg"
                height="25"
              />
            </router-link>
          </template>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button variant="danger">
            <router-link to="">
              <img
                src="/img/icons/svg/out_log_icon_214126.svg"
                alt="logOut!"
                title="logOut!"
                @click.prevent="logOut"
                height="20"
              />
            </router-link>
            Log Out
          </b-dropdown-item-button>
        </b-dropdown> -->
        <div class="user-name">
          <div>{{ firstName }}&nbsp;{{ lastName }}</div>
        </div>
      </div>

      <router-link to="">
        <img src="@/assets/logo.png" alt="Dickinson" id="logo-mobile" />
      </router-link>
    </div>
  </div>
  <div v-else-if="userRoll == 'admin'">
    <b-navbar toggleable type="light" variant="light">
      <AdminUserNavBar v-on:send-logOut="logOut"></AdminUserNavBar>
      <router-link class="navbar-brand" to="">
        <img
          src="@/assets/logo.png"
          alt="Dickinson"
          id="logo"
          class="logo-header"
        />
      </router-link>
      <router-link id="navbar-home" :to="{ name: 'home' }">
        <!-- <Icon icon="bx:home"/> -->
        <img src="/img/icons/svg/homepage_80953.svg" height="30" alt="" />
      </router-link>
    </b-navbar>
  </div>
  <div v-else>
    <b-navbar toggleable type="light" variant="light">
      <ManagerUserNavBar v-on:send-logOut="logOut"></ManagerUserNavBar>
      <router-link class="navbar-brand" to="">
        <img
          src="@/assets/logo.png"
          alt="Dickinson"
          id="logo"
          class="logo-header"
        />
      </router-link>
      <router-link id="navbar-home" :to="{ name: 'home' }">
        <img src="/img/icons/svg/homepage_80953.svg" height="30" alt="" />
      </router-link>
    </b-navbar>
  </div>
</template>

<script>
import AdminUserNavBar from './AdminUserNavBar.vue';
import ManagerUserNavBar from './ManagerUserNavBar.vue';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';

export default {
  props: {},
  name: 'App',
  components: {
    AdminUserNavBar,
    ManagerUserNavBar,
  },
  mounted() {
    // this.$store.dispatch('user/getUserInfo');
  },
  computed: {
    ...mapGetters({
      userRoll: ['user/getRole'],
      firstName: ['user/myFirstName'],
      lastName: ['user/myLastName'],
    }),
  },
  data() {
    return {
      variant: 'light',
      // userRoll: 'admin',
    };
  },
  methods: {
    logOut() {
      const swalWithBootstrapButtons = Swal.mixin({});
      swalWithBootstrapButtons
        .fire({
          text: 'Log Out',
          icon: 'success',
          showConfirmButton: false,
          timer: 1600,
          customClass: 'swal-warning',
        })
        .then(() => {
          this.$store.dispatch('auth/logout', []).then(
            () => {
              this.successful = true;
              // console.log('response logout');
              // localStorage.setItem('logOutCheck', 'true');
              // this.$router.go('/');
            },
            (error) => {
              console.log(error);
              this.successful = false;
              this.$router.go('/');
            }
          );
        });
    },
  },
};
</script>

<style>
.b-dropdown {
  background-color: #fff !important;
}
.display-user-name {
  /* background-color: blue; */
  margin-left: 3%;
  margin-top: 15px;
  float: left;
}
.logout-labor {
  display: inline-block;
  margin-top: 10px;
}
.user-name {
  /* background-color: red; */
  display: inline-block;
  margin-top: 5px;
  margin-left: 10px;
  color: #023517;
  font-weight: bold;
}
/* .btn-primary {
  background-color: #fff !important;
  border: 1px solid #fff !important;
}
.b-icon.bi {
  margin-left: 0px;
}
.dropdown-toggle::after {
  display: none !important;
} */
</style>
<style scoped></style>
