class TokenService {
  getLocalRefreshToken() {
    return localStorage.getItem('refresh_token');
    // const user = JSON.parse(localStorage.getItem("user"));
    // return user?.refreshToken;
  }

  getLocalAccessToken() {
    return localStorage.getItem('access_token');
    // const user = JSON.parse(localStorage.getItem("user"));
    // return user?.access_token;
  }

  updateLocalAccessToken(accessToken) {
    // let user = JSON.parse(localStorage.getItem("user"));
    // user.access_token = accessToken;
    // localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem('access_token', accessToken);
  }

  updateLocalRefreshToken(refreshToken) {
    // let user = JSON.parse(localStorage.getItem("user"));
    // user.access_token = accessToken;
    // localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem('refresh_token', refreshToken);
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  setUserPhoneNumber(phoneNumber) {
    localStorage.setItem('phoneNumber', phoneNumber);
  }

  setOpportunityTime(opportunityTime) {
    localStorage.setItem('opportunityTime', opportunityTime);
  }

  setUser(user) {
    console.log(JSON.stringify(user));
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('firstLogin');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('vuex');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('timeLeft');
    localStorage.removeItem('logOutCheck');
  }
}

export default new TokenService();
